













































































































































import PageHeader from '~/components/app/page-header.vue'
import SeriesSelect from '~/components/autocomplete/series-select.vue'
import SubjectSelect from '~/components/autocomplete/subject-select.vue'
import TutorSelect from '~/components/autocomplete/tutor-select.vue'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {Chart, PeriodStep} from '~/utils/chart'
import {Dict} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import _ from 'lodash'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {VChip, VDataFooter, VDataTable, VDivider, VPagination, VSwitch, VTab, VTabs, VTextField} from 'vuetify/lib'

@Component({
    components: {
        PageHeader,
        VTab,
        VTabs,
        VChip,
        VSwitch,
        VDivider,
        VTextField,
        VDataTable,
        VDataFooter,
        VPagination,
        SeriesSelect,
        SubjectSelect,
        TutorSelect
    },
    metaInfo() {
        return {
            title: 'Course Performance'
        }
    }
})
export default class SeriesReport extends mixins(DatePickerShortcutMixin) {
    static permission = ReportPermission.SeriesReport

    //  META DATA

    quickFilterOrder: string[] = ['Top 10', 'Bottom 10']
    quickFilterPeriod: string[] = ['Day', 'Week', 'Month']

    //  UI DATA
    currTab: number = 0

    selDateRange: number[] = [0, 0]
    realDateRange: number[] = [0, 0]
    selQuickOrder: number = 0
    selQuickPeriod: number = -1

    categories: string[] = []
    chartType: string = 'bar'

    isPercent: boolean = true

    selSeries: number = -1
    selSubject: string = ''
    selTutor: string = ''


    isLoading: boolean = false

    page: number =0
    pageCount: number = 0
    tableHeaders = [
        {text: 'ID', value: '_id', width: 80, sortable: false},
        {text: 'Series', value: 'title', sortable: false},
        {text: 'Subject', value: 'subject', width: 100, sortable: false},
        {text: 'Tutor', value: 'display_name', width: 160, sortable: false},
        {text: 'Avg. retention rate', value: 'retention', width: 160},
        {text: 'Avg. attendance', value: 'attendance', width: 160},
    ]
    tableOption: any = {
        page: 1,
        itemsPerPage: 20
    }

    //  DATA
    titles: Dict<string> = {}
    retentions: any[] = []
    attendances: any[] = []
    enrollRecords: any[] = []


    totalSeriesStuRecord: number = 0
    seriesStuRecord: any[] = []

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    get chartConfig() {
        let config
        if (this.currTab === 0 && this.selQuickPeriod !== -1) {
            config =  {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'straight',
                },
                xaxis: {
                    categories: this.categories,
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                }
            }
        } else {
            config =  {
                chart: {
                    type: this.chartType,
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: this.categories,
                    labels: {
                        formatter: (value) => this.isPercent && this.currTab !== 0 ? Math.round(value * 100).toFixed(0) + '%' : value
                    }
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        title: {
                            formatter: (seriesName) => 'Student',
                        },
                        formatter: (value) => this.isPercent && this.currTab !== 0 ? Math.round(value * 100) + '%' : value
                    },
                }
            }

            if ( this.isPercent && this.currTab !== 0 ) {
                config.xaxis.max = 1
            }
        }

        return config
    }

    get sourceData() {
        this.chartType = 'bar'

        let data: any = []
        let sidField: string = 'series_id'
        let processed

        const ordering = (dataList) => {
            let data = dataList

            if (this.selQuickOrder !== 1)
                data = _.reverse(data)

            if (this.selQuickOrder !== -1)
                data = data.slice(0, 10)

            return data
        }

        if (this.currTab === 0) {
            const aggregated: {} = _.reduce(this.enrollRecords, (result, item) => {
                const old = result[item.sid] || {count: 0, records: []}
                old.records.push(item)
                result[item.sid] = {
                    series_id: item.sid,
                    count: old.count + 1,
                    records: old.records
                }
                return result
            }, {})

            processed = _.sortBy(aggregated, ['count'])
            processed = ordering(processed)
            data = _.map(processed, 'count')


            if (this.selQuickPeriod !== -1) {
                this.chartType = 'line'

                const recordsByDate = _.sortBy(_.reduce(processed, (result, item) => {
                    return [...result, ...item.records]
                }, []), ['paid_time'])

                let periodStep: PeriodStep = PeriodStep.DAY
                if (this.selQuickPeriod === 1) {
                    periodStep = PeriodStep.WEEK
                } else if (this.selQuickPeriod === 2) {
                    periodStep = PeriodStep.MONTH
                }

                const allDate: string[] = []
                const matchPeriod2D: any[] = []
                const newCourseCol = _.reduce(processed, (result, item) => {
                    result[item.series_id] = 0
                    return result
                }, {})

                const allPeriodStart = Chart.generatePeriodStart(this.realDateRange[0], this.realDateRange[1], periodStep)

                for (const periodStart of allPeriodStart) {
                    //  Prepare data
                    const matchPeriod = _.remove(recordsByDate, item => moment(periodStart).isSame(item.paid_time, Chart.transformUnit(periodStep)))
                    const aggregated = _.reduce(matchPeriod, (result, item) => {
                        return {
                            ...result,
                            [item.sid]: (result[item.sid] || 0) + 1
                        }
                    }, {})
                    matchPeriod2D.push(_.values({
                        ...newCourseCol,
                        ...aggregated,
                    }))

                    // Prepare date label
                    const dateLabel = Chart.formatPeriodLabel(periodStart, this.realDateRange[0], this.realDateRange[1], periodStep)
                    allDate.push(dateLabel)
                }

                const matchPeriod2DTrans = _.zip(...matchPeriod2D)

                this.categories = allDate
                return _.map(_.keys(newCourseCol), (item) => {
                    return {
                        name: this.titles[item] || '???',
                        data: matchPeriod2DTrans.shift()
                    }
                })
            }
        } else {
            let fieldName = ''
            let targetData: any[] = []
            switch (this.currTab) {
                case 1:
                    fieldName = 'stay'
                    targetData = this.retentions
                    break
                case 2:
                    fieldName = 'attended'
                    targetData = this.attendances
                    break
            }
            if (this.isPercent) {
                fieldName = 'percentage'
            }
            processed = _.sortBy(targetData, [fieldName])
            processed = ordering(processed)
            data = _.map(processed, fieldName)
        }

        this.categories = _.map(processed, item => {
            return this.titles[item[sidField]] || '???'
        })

        return [{
            data: data
        }]
    }

    created() {
        this.selDateRange = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
        this.realDateRange = [...this.selDateRange]
        this.fetchSeriesStuRecord()
    }

    async fetchSeriesDashboard() {
        const res = await createRequest('/dashboard/series/summary', 'get', {start: this.realDateRange[0], end: this.realDateRange[1]}).send()

        this.titles = res.data.titles
        this.retentions = res.data.retentions
        this.attendances = res.data.attendances
        this.enrollRecords = res.data.enroll_records
    }

    datePickerChanged() {
        this.realDateRange = [moment(this.selDateRange[0]).startOf('day').valueOf(), moment(this.selDateRange[1]).endOf('day').valueOf()]
    }

    @Watch('currTab')
    watchCurrTab(newVal, oldVal) {
        if (newVal !== oldVal) {
            // this.currTab
        }
    }

    clickQuickOrder(idx) {
        this.selQuickOrder = this.selQuickOrder === idx ? -1 : idx
    }

    clickQuickPeriod(idx) {
        this.selQuickPeriod = this.selQuickPeriod === idx ? -1 : idx
    }

    clickQuickSelTutor(tutorId) {
        this.selTutor = tutorId
        this.clickApplyFilter()
    }

    clickApplyFilter() {
        this.fetchSeriesStuRecord()
    }

    clickClearFilter() {
        this.selSeries = -1
        this.selSubject = ''
        this.selTutor = ''
        this.fetchSeriesStuRecord()
    }


    @Watch('tableOption', {deep: true})
    watchTableOption(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
            this.fetchSeriesStuRecord()
        }
    }

    async fetchSeriesStuRecord() {
        const payload: any = {}

        if (this.selSeries !== -1 && !!this.selSeries.toString().length) {
            payload.keyword = this.selSeries
        }

        if (!!this.selSubject) {
            payload.subject = this.selSubject
        }

        if (!!this.selTutor) {
            payload.tutor = this.selTutor
        }

        const {sortBy, sortDesc, page, itemsPerPage} = this.tableOption
        payload.limit = itemsPerPage
        payload.skip = (page - 1) * itemsPerPage

        if (!!sortBy) {
            payload.sort = sortBy[0]
        }

        if (!!sortDesc) {
            payload.order = sortDesc[0] ? -1 : 1
        }


        this.isLoading = true
        const res = await createRequest('/dashboard/series', 'get', payload).send()

        this.totalSeriesStuRecord = res.data.count || 0
        this.seriesStuRecord = res.data.series

        this.isLoading = false
    }

    @Watch('realDateRange')
    async watchRealDateRange(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
            await this.fetchSeriesDashboard()
        }
    }
}
